import './Form.scss';

import cn from 'classnames';
import React, { useState } from 'react';

import Icon from '../images/carret.inline.svg';
import Button from './Button';

const PRICE = 16;
const shopifyCheckoutUrl = (qty = 1) =>
  `https://rule-of-three-landscape.myshopify.com/cart/37623926685892:${qty}?channel=buy_button`;

const Form = ({ withSelect }) => {
  const [qty, setQty] = useState(1);
  const [open, setOpen] = useState(false);

  const rootCx = cn('form', {
    'form--enhanced': withSelect,
  });

  const handleSetQty = (value) => {
    setQty(value);
    setOpen(false);
  };

  const handleSubmit = (e) => {
    // location.href = shopifyCheckoutUrl(qty);
    e.preventDefault();
  };

  return (
    <form className={rootCx} onSubmit={handleSubmit}>
      {withSelect && (
        <div className="form__select">
          <input type="hidden" name="qty" value={qty} />
          {!open ? (
            <Button isSecondary onClick={() => setOpen(true)}>
              <span>{`Qty: ${qty}`}</span>
              <Icon />
            </Button>
          ) : (
            <ul className="form__dropdown">
              <li className="form__dropdown-item">
                <button type="button" onClick={() => handleSetQty(4)}>
                  Four
                </button>
              </li>
              <li className="form__dropdown-item">
                <button type="button" onClick={() => handleSetQty(3)}>
                  Three
                </button>
              </li>
              <li className="form__dropdown-item">
                <button type="button" onClick={() => handleSetQty(2)}>
                  Two
                </button>
              </li>
              <li className="form__dropdown-item">
                <button type="button" onClick={() => handleSetQty(1)}>
                  One
                </button>
              </li>
            </ul>
          )}
        </div>
      )}
      <Button isPrimary href={shopifyCheckoutUrl(qty)} tag="a" target="_blank">
        <span>Add to Cart!</span>
        <span>{`$${qty * PRICE}`}</span>
      </Button>
    </form>
  );
};

export default Form;

import './Button.scss';

import cn from 'classnames';
import React from 'react';

const Button = ({ children, isPrimary, isSecondary, tag, ...props }) => {
  const rootCx = cn('button', {
    'button--primary': isPrimary,
    'button--secondary': isSecondary,
  });

  const Tag = tag || 'button';
  const type = tag ? '' : 'button';

  return (
    <Tag className={rootCx} type={type} {...props}>
      {children}
    </Tag>
  );
};

export default Button;

import './CardBoxPlayer.scss';

import cn from 'classnames';
import React from 'react';

import BoxVideoUrl from '../videos/box-2_3mb.mp4';

const CardBoxPlayer = () => {
  const rootCx = cn('card-box-player');

  return (
    <div className={rootCx}>
      <video className="card-box-player__video" autoPlay playsInline loop muted>
        <source src={BoxVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default CardBoxPlayer;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CardBoxPlayer from '../components/CardBoxPlayer';
import Form from '../components/Form';
import { pageMount } from '../state/page';

const LearnPage = ({ onMount = () => {} }) => {
  /* Effect handling page mount */
  useEffect(() => {
    onMount();
  }, []);

  return (
    <div className="page page--full">
      <CardBoxPlayer />
      <Form withSelect />
    </div>
  );
};

export default connect(null, (dispatch) => ({
  onMount: () => {
    dispatch(pageMount('buy'));
  },
}))(LearnPage);
